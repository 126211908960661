import React from 'react';
import styled, { css } from 'styled-components';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';
import { ImageThumbnail, StringField } from '@/typings/model';
import { colors } from '@/constants/theme';

type Props = {
  title: StringField;
  date?: StringField;
  banner?: ImageThumbnail;
};

function Hero({ title, date, banner }: Props) {
  return (
    <Component banner={banner}>
      {banner && (
        <Background>
          <BackgroundInner>
            <Picture mobileSmall={convertThumbnailToPictureImage(banner)} />
          </BackgroundInner>
        </Background>
      )}
      <Inner>
        <Title>{title}</Title>
        {date ? (
          <Date>
            {date.replace(/(\d+)-(\d+)-(\d+)/, '$3-$2-$1').replace(/-/g, '.')}
          </Date>
        ) : null}
      </Inner>
    </Component>
  );
}

const Background = styled.div`
  position: absolute;
  top: 32px;
  bottom: 0;
  left: 0;
  right: 0;

  ${media.laptop(css`
    top: 24px;
  `)}
`;
const BackgroundInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: brightness(0.4);
  }

  div,
  picture {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  max-width: 1056px;
  margin: 0 auto;
  position: relative;
  z-index: 2;

  ${media.laptop(css`
    max-width: 792px;
  `)}

  ${media.tabletSmall(css`
    max-width: 538px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`;

const Title = styled.h2`
  margin: 0;
  font-weight: 500;
  font-size: 72px;
  line-height: 80px;

  ${media.laptop(css`
    font-size: 64px;
    line-height: 72px;
  `)}

  ${media.tablet(css`
    font-size: 48px;
    line-height: 56px;
  `)} ${media.mobile(css`
    font-size: 34px;
    line-height: 42px;
  `)} @media(max-width: 359 px) {
    font-size: 30px;
  }
`;

const Date = styled.span`
  display: block;
  margin-top: 42px;
  font-size: 30px;
  line-height: 36px;

  ${media.laptop(css`
    margin-top: 32px;
    font-size: 20px;
    line-height: 26px;
  `)}

  ${media.tablet(css`
    margin-top: 20px;
    font-size: 18px;
    line-height: 22px;
  `)}
`;

const Component = styled.div<{ banner?: ImageThumbnail }>`
  padding-top: 92px;
  position: relative;

  ${media.laptop(css`
    padding-top: 64px;
  `)}

  ${media.tablet(css`
    padding-top: 31px;
  `)}

  ${media.mobile(css`
    padding-top: 20px;
  `)}

  ${(props) =>
    props.banner
      ? css`
          min-height: 728px;
          display: flex;
          align-items: center;

          ${Inner} {
            color: ${colors.white};
          }

          ${media.laptop(css`
            min-height: 448px;
          `)}
        `
      : ''}
`;

export default Hero;
