import React from 'react';
import styled from 'styled-components';

import Picture from '@/components/Picture';

type Props = {
  sliderItem: string;
};

function SliderItem({ sliderItem }: Props) {
  return (
    <Component>
      <Picture
        mobileSmall={{
          src: sliderItem,
        }}
      />
    </Component>
  );
}

const Component = styled.div`
  width: 100%;
  height: 100%;

  div,
  picture {
    width: 100%;
    height: 100%;
  }
  img {
    margin: 0 !important;
    border-radius: 0 !important;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default SliderItem;
