import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  body: string;
  isShare?: boolean;
};

function Static({ body, isShare }: Props) {
  const staticRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!staticRef?.current) return;

    const linkList = staticRef.current.getElementsByTagName('a');

    for (let i = 0; i < linkList.length + 1; i++) {
      const link = linkList[i];
      const hrefLink = link?.getAttribute('href');

      if (hrefLink?.includes('./' || `${window.location.host}`)) return;

      link?.setAttribute('target', '_blank');
    }
  }, []);

  return (
    <Component isShare={isShare}>
      <Inner
        isShare={isShare}
        ref={staticRef}
        dangerouslySetInnerHTML={{ __html: body ?? '' }}
      />
    </Component>
  );
}

const Component = styled.div<{ isShare?: boolean }>`
  max-width: 1056px;

  ${media.laptop(css`
    max-width: 792px;
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}

  ${(props) =>
    props.isShare
      ? css`
          margin-left: 76px;

          ${media.laptop(css`
            margin-left: 72px;
          `)}

          ${media.tablet(css`
            margin-left: 0;
          `)}
        `
      : css`
          margin: 0 auto;

          ${media.tablet(css`
            //margin: 0;
          `)}
        `}
`;

const Inner = styled.div<{ isShare?: boolean }>`
  h2 {
    margin: 0 0 42px;
    font-weight: 500;
    font-size: 72px;
    line-height: 80px;

    ${media.laptop(css`
      margin-bottom: 32px;
      font-size: 64px;
      line-height: 72px;
    `)}

    ${media.tablet(css`
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 38px;
    `)}
    
    ${media.mobile(css`
      font-size: 28px;
      line-height: 34px;
    `)}
  }

  h3 {
    font-weight: 500;
    font-size: 50px;
    line-height: 58px;
    margin: 3px 0 34px;

    ${media.laptop(css`
      margin: 0 0 16px;
      font-size: 48px;
      line-height: 56px;
    `)}

    ${media.tablet(css`
      font-size: 32px;
      line-height: 38px;
    `)}
    
    ${media.mobile(css`
      font-size: 28px;
      line-height: 34px;
    `)}
  }

  h4 {
    margin: 84px 0 27px;
    font-weight: 500;
    font-size: 32px;
    line-height: 42px;

    ${media.laptop(css`
      margin: 56px 0 16px;
      font-size: 24px;
      line-height: 32px;
    `)}

    ${media.tablet(css`
      margin: 50px 0 16px;
      font-size: 22px;
      line-height: 28px;
    `)}
    
    ${media.mobile(css`
      margin: 40px 0 16px;
      font-size: 16px;
      line-height: 20px;
    `)}
  }

  p {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}

    ${media.tablet(css`
      font-size: 18px;
      line-height: 22px;
    `)}
    
    ${media.mobile(css`
      font-size: 14px;
      line-height: 20px;
    `)}
  }

  table {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 34px;

    ${media.laptop(css`
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 26px;
    `)}
  }

  b,
  strong {
    font-weight: 700;
  }

  a {
    color: ${colors.orange};
    text-decoration: underline;
    text-decoration-color: ${colors.orange};
    transition: all 0.3s linear;

    ${media.mobile(css`
      word-wrap: break-word;
    `)}

    &:hover {
      text-decoration-color: transparent;
    }
  }

  img {
    margin: 40px auto 20px;
    max-width: 100%;
    object-fit: cover;
    border-radius: 36px;
    overflow: hidden;

    ${media.laptop(css`
      margin: 24px auto 16px;
      border-radius: 24px;
    `)}

    &+figcaption {
      margin-top: 20px;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: ${colors.grayLight};

      ${media.laptop(css`
        margin-top: 16px;
        font-size: 16px;
        line-height: 24px;
      `)}
    }
  }

  .image-style-side {
    float: left;
    margin: 0 20px 20px 0;

    img {
      margin: 0;
      width: auto;
      border-radius: 0;
    }
  }

  figure {
    margin-bottom: 34px;
    ${media.laptop(css`
      margin-bottom: 26px;
    `)}
  }

  ul,
  ol {
    margin-bottom: 84px;

    ${media.laptop(css`
      margin-bottom: 56px;
    `)}

    ${media.tablet(css`
      margin-bottom: 34px;
    `)}
    
    ${media.mobile(css`
      margin-bottom: 60px;
    `)}
  }

  ul {
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        content: '';
        top: 12px;
        left: 0;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          top: 16px;
          width: 8px;
          height: 8px;
        }
      `)}

      ${media.tablet(css`
        text-indent: 24px;
        font-size: 18px;
        line-height: 36px;

        &:before {
          top: 13px;
        }
      `)}
      
      ${media.mobile(css`
        text-indent: 20px;
        font-size: 14px;
        line-height: 20px;

        &:before {
          top: 5px;
        }
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ol {
    counter-reset: list;
    li {
      position: relative;
      text-indent: 40px;
      font-weight: normal;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 24px;

      &:before {
        position: absolute;
        counter-increment: list;
        content: counter(list) '.';
        font-weight: 600;
        top: 0;
        left: -38px;
        color: ${colors.orange};
      }

      ${media.laptop(css`
        margin-bottom: 0;
        text-indent: 28px;
        font-size: 20px;
        line-height: 40px;

        &:before {
          left: -28px;
        }
      `)}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table {
    overflow-x: auto;

    ${(props) =>
      props.isShare
        ? css`
            max-width: 1056px;

            ${media.laptop(css`
              max-width: 792px;
            `)}
          `
        : css`
            margin: 0 -136px;
            max-width: 1328px;

            ${media.laptop(css`
              margin: 0 -102px;
              max-width: 996px;
            `)}
          `}
  }

  table {
    table-layout: fixed;
    width: 100%;
    max-width: 100vw;
    margin: 56px auto 0;

    ${media.tablet(css`
      margin: 30px auto 0;
    `)}

    ${media.mobile(css`
      margin: 20px auto 0;
    `)}

    thead {
      th {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: ${colors.grayLight};
        padding-bottom: 48px;

        ${media.laptop(css`
          font-size: 12px;
          padding-bottom: 40px;
        `)}

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          margin-bottom: 0;
          ${media.laptop(css`
            font-size: 12px;
          `)}
        }
      }
    }

    tbody {
      tr {
        td {
          position: relative;
          padding: 32px 0;
          font-weight: 500;
          font-size: 20px;
          line-height: 26px;
          color: ${colors.black};
          vertical-align: baseline;

          a {
            text-decoration-color: transparent;

            &:hover {
              text-decoration-color: ${colors.orange};
            }
          }

          p {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 0;
            ${media.laptop(css`
              font-size: 16px;
              line-height: 24px;
            `)}
          }

          ${media.laptop(css`
            padding: 24px 0;
            font-size: 16px;
            line-height: 24px;
          `)}

          ${media.tablet(css`
            padding: 20px 0;
          `)}
          
          ${media.mobile(css`
            font-size: 14px;
            line-height: 22px;
          `)}

          &:after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: -3px;
            height: 1px;
            background: ${colors.grayLight};
          }

          &:last-child {
            &:after {
              right: 0;
            }
          }
        }

        &:first-child {
          td {
            padding-top: 0;
          }
        }

        &:last-child {
          td {
            padding-bottom: 0;
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }
`;

export default Static;
