import React from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as QuoteIcon } from '@/assets/svg/quote.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import Person from './components/Person';

type Props = {
  text: string;
  name: string;
  position: string;
  image: string;
};

function Quote({ text, position, name, image }: Props) {
  return (
    <Component>
      <Top>
        <Left>
          <QuoteIcon />
        </Left>
        <Right>
          <Text>{text}</Text>
        </Right>
      </Top>
      <Bottom>
        <Person position={position} name={name} image={image} />
      </Bottom>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1056px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 792px;
  `)}
`;

const Top = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;

  ${media.laptop(css`
    margin-bottom: 24px;
  `)}

  ${media.tablet(css`
    margin-bottom: 30px;
  `)}
`;

const Bottom = styled.div``;

const Left = styled.div`
  svg {
    width: 104px;
    height: 104px;

    ${media.laptop(css`
      width: 64px;
      height: 64px;
    `)}

    ${media.tablet(css`
      width: 60px;
      height: 60px;
    `)}
    
    ${media.mobile(css`
      width: 36px;
      height: 36px;
    `)}
  }
`;

const Right = styled.div`
  flex: 1 1 920px;
  max-width: 920px;

  ${media.laptop(css`
    flex: 1 1 704px;
    max-width: 704px;
  `)}

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin-left: 20px;
  `)}
  
  ${media.mobile(css`
    margin-left: 10px;
  `)}
`;

const Text = styled.p`
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 500 !important;
  font-size: 54px !important;
  line-height: 64px !important;
  color: ${colors.black400}!important;

  ${media.laptop(css`
    font-size: 40px !important;
    line-height: 48px !important;
  `)}

  ${media.tablet(css`
    font-size: 28px !important;
    line-height: 34px !important;
  `)}
  
  ${media.mobile(css`
    font-size: 22px !important;
    line-height: 28px !important;
  `)}
`;

export default Quote;
