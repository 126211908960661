import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Picture from '@/components/Picture';

type Props = {
  name: string;
  position: string;
  image: string;
};

function Person({ name, position, image }: Props) {
  return (
    <Component>
      <Inner>
        <Left>
          <Image>
            {image && (
              <Picture
                mobileSmall={{
                  src: image,
                }}
              />
            )}
          </Image>
        </Left>
        <Right>
          <Name>{name}</Name>
          <Position>{position}</Position>
        </Right>
      </Inner>
    </Component>
  );
}

const Component = styled.div``;

const Inner = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Left = styled.div`
  flex: 1 1 104px;
  max-width: 104px;

  ${media.laptop(css`
    flex: 1 1 64px;
    max-width: 64px;
  `)}

  ${media.tablet(css`
    flex: 1 1 60px;
    max-width: 60px;
  `)}
  
  ${media.mobile(css`
    flex: 1 1 64px;
    max-width: 64px;
  `)}
`;
const Right = styled.div`
  flex: 1 1 920px;
  max-width: 920px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.laptop(css`
    flex: 1 1 704px;
    max-width: 704px;
  `)}

  ${media.tablet(css`
    flex: auto;
    max-width: none;
    margin-left: 20px;
  `)}
`;

const Name = styled.span`
  display: block;
  font-weight: 500;
  font-size: 32px;
  line-height: 42px;
  color: ${colors.black400};

  ${media.laptop(css`
    font-size: 24px;
    line-height: 32px;
  `)}

  ${media.tablet(css`
    font-size: 20px;
    line-height: 24px;
  `)}
  
   ${media.mobile(css`
    font-size: 16px;
    line-height: 20px;
  `)}
`;

const Position = styled.span`
  display: block;
  margin-top: 15px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.black400};

  ${media.laptop(css`
    margin-top: 9px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.tablet(css`
    margin-top: 10px;
    font-size: 16px;
    line-height: 22px;
  `)}
  
  ${media.mobile(css`
    margin-top: 4px;
    font-size: 12px;
    line-height: 20px;
  `)}
`;

const Image = styled.div`
  width: 104px;
  height: 104px;
  border-radius: 100%;
  overflow: hidden;

  ${media.laptop(css`
    width: 64px;
    height: 64px;
  `)}

  ${media.tablet(css`
    width: 60px;
    height: 60px;
  `)}
  
  ${media.mobile(css`
    width: 64px;
    height: 64px;
  `)}

  div,
  picture,
  img {
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
`;

export default Person;
