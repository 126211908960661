import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ReactComponent as PlayVideo } from '@/assets/svg/play.svg';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Picture from '@/components/Picture';

type Props = {
  videoUrl?: string;
  poster: string;
};

function VideoBlock({ videoUrl, poster }: Props) {
  const [isPlay, setPlay] = useState(false);
  const url = videoUrl + '?autoplay=1';

  return (
    <Component>
      <Inner>
        {isPlay ? (
          <IframeWrapper>
            <iframe
              src={url}
              frameBorder="0"
              allow="accelerometer; fullscreen, autoplay"
              allowFullScreen
              scrolling="no"
            ></iframe>
          </IframeWrapper>
        ) : (
          <>
            <BackgroundImage>
              {poster && (
                <Picture
                  mobileSmall={{
                    src: poster,
                  }}
                />
              )}
            </BackgroundImage>
            <Content onClick={() => setPlay(true)}>
              <PlayVideo />
              <PlayText>Воспроизвести</PlayText>
            </Content>
          </>
        )}
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1056px;
  width: 100%;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 792px;
  `)}
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  border-radius: 36px;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    padding-bottom: 56.25%;
  }

  ${media.laptop(css`
    border-radius: 24px;
  `)}
`;

const IframeWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.2s linear;

  iframe {
    width: 100%;
    height: 100%;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  picture {
    width: 100%;
    height: 100%;
  }

  img {
    margin: 0 !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
    object-position: left !important;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  color: ${colors.white};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 120px;
    height: 120px;
    transition: all 0.3s linear;

    ${media.laptop(css`
      width: 80px;
      height: 80px;
    `)}

    ${media.mobile(css`
      width: 40px;
      height: 40px;
    `)}
  }

  &:hover {
    cursor: pointer;
    color: ${colors.orange};
  }

  ${media.laptop(css`
    padding-top: 20px;
  `)}
`;

const PlayText = styled.span`
  margin-top: 23px;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  transition: all 0.3s linear;

  ${media.laptop(css`
    margin-top: 12px;
    font-size: 16px;
    line-height: 24px;
  `)}

  ${media.mobile(css`
    margin-top: 5px;
    font-size: 12px;
    line-height: 20px;
  `)}
`;

export default VideoBlock;
