import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import { isBrowser } from '@tager/web-core';

import { media } from '@/utils/mixin';
import { breakpoints, colors } from '@/constants/theme';
import { ReactComponent as FacebookIcon } from '@/assets/svg/facebook-share.svg';
import { ReactComponent as VkontakteIcon } from '@/assets/svg/vk-share.svg';
import share from '@/services/share';
import { getScrollBarWidth } from '@/utils/common';

function Share() {
  const [scrollWidth, setScrollWidth] = useState(0);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }
    setScrollWidth(getScrollBarWidth());
  }, []);
  return (
    <Component scrollBarWidth={scrollWidth}>
      <Inner>
        <List>
          <Item>
            <ShareButton onClick={() => share('facebook')}>
              <FacebookIcon />
            </ShareButton>
          </Item>

          <Item>
            <ShareButton onClick={() => share('vk')}>
              <VkontakteIcon />
            </ShareButton>
          </Item>
          {/*<Item>*/}
          {/*  <ShareButton>*/}
          {/*    <InstagramIcon />*/}
          {/*  </ShareButton>*/}
          {/*</Item>*/}
        </List>
      </Inner>
    </Component>
  );
}

const Component = styled.div<{ scrollBarWidth: number }>`
  ${media.tablet(css`
    padding: 30px 0;
    background: ${colors.black};
    width: 100vw;
  `)};
  ${({ scrollBarWidth }) => css`
    @media (max-width: ${breakpoints.laptop}px) {
      margin-left: calc(-50vw + 50% - ${(scrollBarWidth / 2).toFixed(1)}px);
    }
  `};
}
`;

const Inner = styled.div`
  position: sticky;
  top: 60px;

  ${media.laptop(css`
    top: 40px;
  `)}
`;

const List = styled.div`
  padding-top: 2px;

  ${media.laptop(css`
    padding-top: 0;
  `)}

  ${media.tablet(css`
    display: flex;
    align-items: center;
    justify-content: center;
  `)}
`;

const Item = styled.div`
  margin-bottom: 6px;

  ${media.laptop(css`
    margin-bottom: 3px;
  `)}
  ${media.tablet(css`
    margin-bottom: 0;
  `)}
  &:last-child {
    margin-bottom: 0;
  }
`;

const ShareButton = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s linear;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${colors.gray50};
  }

  svg {
    width: 36px;
    height: 36px;
    color: ${colors.black};
    transition: all 0.3s linear;
  }

  ${media.laptop(css`
    width: 40px;
    height: 40px;

    svg {
      width: 24px;
      height: 24px;
    }
  `)}

  ${media.tablet(css`
    svg {
      color: ${colors.white};
    }
  `)}
`;

export default Share;
