import { useRouter } from 'next/router';

import { Nullish } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { getParamAsString } from '@/utils/common';
import { PostFull } from '@/typings/model';
import { selectPostByAlias } from '@/store/reducers/tager/post';

function useCurrentPost(): Nullish<PostFull> {
  const router = useRouter();

  const postAlias = getParamAsString(router.query.postAlias);

  return useTypedSelector((state) => selectPostByAlias(state, postAlias));
}

export default useCurrentPost;
