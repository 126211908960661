import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from 'swiper';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { ReactComponent as NextIcon } from '@/assets/svg/slider-next.svg';
import { ReactComponent as PrevIcon } from '@/assets/svg/slider-prev.svg';

import SliderItem from './components/SliderItem';

SwiperCore.use([Navigation, Autoplay, Pagination, EffectFade]);

type Props = {
  gallery?: Array<string>;
  isSingleNews?: boolean;
};

function SectionSlider({ gallery, isSingleNews }: Props) {
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  return (
    <Component>
      <SliderContainer>
        <Swiper
          initialSlide={0}
          slidesPerView={1}
          // speed={500}
          // autoplay={{ delay: 4000, disableOnInteraction: false }}
          // loop={true}
          pagination={{
            el: `.swiper-pagination`,
            type: 'bullets',
            clickable: true,
          }}
          navigation={{
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev',
          }}
          onSlideChange={(swiper) => setActiveSlideIndex(swiper.activeIndex)}
        >
          {gallery?.map((sliderItem, index) => {
            return (
              <SwiperSlide key={index}>
                <SwiperSlideWrapper isSingleNews={isSingleNews}>
                  <SwiperSlideInner>
                    <SliderItem sliderItem={sliderItem} />
                  </SwiperSlideInner>
                </SwiperSlideWrapper>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <ButtonPrev className="swiper-prev">
          <PrevIcon />
        </ButtonPrev>
        <ButtonNext className="swiper-next">
          <NextIcon />
        </ButtonNext>
        <ItemsPagination className={`swiper-pagination swiper-pagination`} />
      </SliderContainer>
    </Component>
  );
}

const Component = styled.div``;

const SliderContainer = styled.div`
  position: relative;

  .swiper-pagination {
    position: absolute;
    bottom: 64.5px;
    left: 50%;
    transform: translateX(-50%);

    ${media.laptop(css`
      bottom: 40px;
    `)}

    ${media.mobile(css`
      bottom: 30px;
    `)}
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin-right: 15px;
    background: ${colors.white50};
    opacity: 1;

    &-active {
      background: ${colors.white};
    }

    ${media.laptop(css`
      width: 10px;
      height: 10px;
      margin-right: 10px;
    `)}
    &:last-child {
      margin-right: 0;
    }
  }
`;

const SwiperSlideWrapper = styled.div<{
  isSingleNews: Props['isSingleNews'];
}>`
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: 50%;

    ${media.tablet(css`
      padding-bottom: 61.459%;
    `)}

    ${media.mobile(css`
      padding-bottom: min(141.67%, 510px);
    `)}
  }

  ${({ isSingleNews }) =>
    isSingleNews &&
    css`
      &:before {
        padding-bottom: 50%;

        ${media.tablet(css`
          padding-top: 16.8%;
        `)}

        ${media.mobile(css`
          padding-top: 18.1%;
        `)}
      }
    `}
`;
const SwiperSlideInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const ItemsPagination = styled.div``;

const ButtonPrev = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    left: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    left: 19px;

    width: 56px;
    height: 56px;

    svg {
      left: -1px;
      width: 24px;
      height: 24px;
    }
  `)}
  ${media.mobile(css`
    width: 46px;
    height: 46px;
    left: 20px;

    svg {
      left: -1px;
      width: 18px;
      height: 18px;
    }
  `)}
  &:hover {
    transform: scale(1.1);
  }
`;

const ButtonNext = styled.div`
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 2;
  cursor: pointer;
  will-change: transform;
  transition: all 0.3s linear;

  width: 84px;
  height: 84px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  background: ${colors.white};

  svg {
    position: relative;
    right: -2px;
    width: 36px;
    height: 36px;
    pointer-events: none;
    will-change: transform;
  }

  ${media.laptop(css`
    bottom: 20px;
    right: 19px;

    width: 56px;
    height: 56px;

    svg {
      right: -1px;
      width: 24px;
      height: 24px;
    }
  `)}
  ${media.mobile(css`
    width: 46px;
    height: 46px;
    right: 20px;

    svg {
      left: -1px;
      width: 18px;
      height: 18px;
    }
  `)}
  &:hover {
    transform: scale(1.1);
  }
`;

export default SectionSlider;
