import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useStore } from 'react-redux';
import { useRouter } from 'next/router';

import { ShortCodeService } from '@tager/web-core';

import { media } from '@/utils/mixin';
import Quote from '@/components/Quote/Quote';
import VideoBlock from '@/components/VideoBlock/VideoBlock';
import SectionSlider from '@/components/SectionSlider';
import useCurrentPost from '@/hooks/useCurrentPost';

import Share from './components/Share';
import Static from './components/Static';

type Props = {
  body: string;
};

const videoShortCodeService = new ShortCodeService('video', ['url', 'poster']);
const testimonialShortCodeService = new ShortCodeService('testimonial', [
  'text',
  'name',
  'position',
  'image',
]);
const sliderShortCodeService = new ShortCodeService('gallery', ['number']);

function Main({ body }: Props) {
  const [content, setContent] = useState<string>(body);
  const store = useStore();
  const router = useRouter();
  const post = useCurrentPost();

  function SplitString(images: string) {
    return images.split(',');
  }

  useEffect(() => {
    let replacedBody = body;

    replacedBody?.replaceAll("''", '"');

    if (!replacedBody) {
      return;
    }

    replacedBody =
      videoShortCodeService.replaceShortCodeByElement(replacedBody);
    replacedBody =
      testimonialShortCodeService.replaceShortCodeByElement(replacedBody);

    replacedBody =
      sliderShortCodeService.replaceShortCodeByElement(replacedBody);

    setContent(replacedBody);
  }, [body]);

  useEffect(() => {
    videoShortCodeService.renderComponent(
      (props: { url: string; poster: string }) => (
        <VideoWrapper>
          <VideoBlock
            videoUrl={`https://www.youtube.com/embed/${props.url}`}
            poster={props.poster}
          />
        </VideoWrapper>
      )
    );

    testimonialShortCodeService.renderComponent(
      (props: {
        text: string;
        name: string;
        position: string;
        image: string;
      }) => {
        return (
          <QuoteWrapper>
            <Quote
              text={props.text}
              name={props.name}
              position={props.position}
              image={props.image}
            />
          </QuoteWrapper>
        );
      }
    );

    sliderShortCodeService.renderComponent((props: { number: string }) => {
      if (!post) {
        return null;
      }

      const gallery = post.additionalFields.galleries[
        Number(props.number) - 1
      ].gallery.map((item) => item?.url);

      if (!gallery) {
        return null;
      }

      return (
        <SectionSlider gallery={SplitString(gallery.join(','))} isSingleNews />
      );
    });
  }, [router, store, content, post]);

  return (
    <Component>
      <Inner>
        <Share />
        <Static body={content} isShare={true} />
      </Inner>
    </Component>
  );
}

const Component = styled.div`
  margin: 60px 0 120px;

  ${media.laptop(css`
    margin: 40px 0 70px;
  `)}

  ${media.tablet(css`
    margin: 50px 0 50px;
  `)}
`;

const Inner = styled.div`
  max-width: 1328px;
  margin: 0 auto;
  display: flex;

  ${media.laptop(css`
    max-width: 1016px;
  `)}

  ${media.tablet(css`
    flex-direction: column-reverse;
    max-width: 792px;
  `)}
`;

const QuoteWrapper = styled.div`
  margin-top: 72px;

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 50px;
  `)}
`;

const VideoWrapper = styled.div`
  margin-top: 72px;

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 40px;
  `)}
  
  ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const SliderWrapper = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: 72px auto 0;
  position: relative;

  &:before {
    display: block;
    content: '';
    padding-bottom: calc(100vw / 2);

    @media (min-width: 1920px) {
      padding-bottom: 960px;
    }
  }

  ${media.laptop(css`
    margin-top: 56px;
  `)}

  ${media.tablet(css`
    margin-top: 40px;
  `)}
  
   ${media.mobile(css`
    margin-top: 30px;
  `)}
`;

const SliderInner = styled.div`
  position: absolute;
  content: '';
  width: calc(100vw - 0.375rem);
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: 1921px) {
    width: calc(1920px - 0.375rem);
  }
`;

export default Main;
